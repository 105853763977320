<!-- eslint-disable vue/no-mutating-props -->
<template>
  <Card>
    <template #title>
      <h2>Destek Detayı</h2>
    </template>

    <template #subtitle>
      <p>Her yarım saatlik destek bedeli <strong>{{ pricing.normal }} TL</strong>'dir,
        ancak sadece yarım saat destek olacaksanız destek bedeli
        <strong>{{ pricing.expensive }} TL</strong>'dir.
      </p>
    </template>

    <template #content>

      <!-- ar seçsin -->
      <template v-if="currentItem.type === 'ar'">
        <p class="mb-4">Seçilen program(lar)ı daha sonra size e-posta ile bildireceğiz.</p>
        <p>Lüften sizin için kaç saatlik program seçilmesini istediğinizi belirtiniz.</p>
        <hr class="border-b my-4" />

        <div>
          <form-row-item label="Destek miktarı (saat)">
            <InputNumber v-model="currentItem.desiredHours" :min="0.5" :max="maxHours" :step="0.5"
              show-buttons button-layout="horizontal" :allow-empty="false"
              @update:modelValue="fixHours()"
              :minFractionDigits="0" :maxFractionDigits="1" />
          </form-row-item>
          <form-row-item label="Radyonun sizin için seçeceği programa ilişkin isteklerinizi (program türü, günü, saati vs...) belirtiniz">
            <Textarea v-model="currentItem.programCriteria" class="w-full"
              placeholder='Örneğin "Caz programı olsun"' />
          </form-row-item>
        </div>
      </template>

      <!-- user selection -->
      <!-- user selection -->
      <!-- user selection -->
      <template v-if="currentItem.type === 'user'">
        <p class="mb-4">
          Destek olmak istediğiniz programı seçtikten sonra program tarih(ler)ini
          ve destek vermek istediğiniz süreyi belirleyiniz.
        </p>
        <p>Onay adımından sonra başka programlara da destek olabilirsiniz.</p>

        <hr class="border-b my-4" />

        <div>
          <form-row-item label="Program seçiniz">
            <Dropdown v-model="programId" required class="w-full md:w-72"
              @change="loadProgramSlots" :loading="ui.slotsLoading"
              :options="programs" optionValue="id" optionLabel="name" dataKey="id"  />
          </form-row-item>

          <form-row v-if="slots.length" class="mt-8">
            <p class="mb-4">Hangi gün(ler) ve kaç(ar) saat destek olmak istediğinizi seçiniz.</p>

            <ul>
              <SlotListing v-for="slot of slots.slice(0, 5)"
                :programSlot="slot" :key="slot.date"
                :credits-to-hours-str="creditsToHoursStr"
                @update:value="slotSelected(slot, $event)" />

              <li v-if="slots.length > 5 && ! ui.showAllSlots" class="py-2">
                <Button label="Hepsini göster..." @click="ui.showAllSlots = true"
                  class="p-button-text" icon="pi pi-chevron-down" />
              </li>

              <template v-if="ui.showAllSlots">
                <SlotListing v-for="slot of slots.slice(5)"
                  :programSlot="slot" :key="slot.date"
                  :credits-to-hours-str="creditsToHoursStr"
                  @update:value="slotSelected(slot, $event)" />
              </template>
            </ul>
          </form-row>

          <form-row v-if="programId && ! slots.length && ! ui.slotsLoading"
            class="text-orange-500 flex gap-2 items-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 28px" ></i>
              <i>"{{ programs.find(p => p.id === programId).name }}" programının tüm anonsları dolmuştur.
              Lüften başka program seçiniz.</i>
          </form-row>
        </div>
      </template>


    </template>

    <template #footer>
      <div class="text-right">

        <Button size="small" label="DEVAM" raised icon="pi pi-arrow-circle-right"
          :disabled="currentItem.type === 'user' && ! currentItem.slots.length"
          @click="$router.push({name: 'support', params: {step: 'anons'}})"  />

      </div>
    </template>
  </Card>
</template>

<script>
import SlotListing from "./slot-listing.vue";

export default {
  components: {
    SlotListing
  },

  props: ["currentItem", "programs", "pricing"],

  data () {
    return {
      programId: null,
      slots: [],
      ui: {
        slotsLoading: false,
        showAllSlots: false,
      },
    }
  },

  computed: {
    maxHours () {
      return 120;
    }
  },

  methods: {
    fixHours () { //prevents values to be non multiples of .5
      let val = +this.currentItem.desiredHours.toFixed(1);
      if (val % .5) val = Math.round(val);
      if (! val) val = 0.5;
      if (val > this.maxHours) val = this.maxHours;
      //eslint-disable-next-line
      this.currentItem.desiredHours = val;
      //eslint-disable-next-line
      this.currentItem.desiredCredits = this.currentItem.desiredHours * 2;
    },

    creditsToHoursStr (credits) {
      const hours = credits * 0.5;
      return hours === 0.5 ? "Yarım saat" : (hours + ' saat');
    },

    slotSelected (slot, credits) {
      slot.desiredCredits = credits;
      //eslint-disable-next-line
      this.currentItem.program = this.programs.find(p => p.id === this.programId).name;
      //eslint-disable-next-line
      this.currentItem.slots = this.slots
        .filter(s => s.desiredCredits)
        //filter out unnnecessary fields:
        .map(s => ({date: s.date, ppId: s.ppId, desiredCredits: s.desiredCredits}));
    },

    async loadProgramSlots (e) {
      this.ui.slotsLoading = true;
      this.slots = [];

      const ret = (await this.$get("/reservation/available-slots?programId=" + e.value));

      ret.slots.forEach(slot => { //slot has "date" and "qty" fields. qty represents available half-hours.
        slot.options = []; //augment with options for the ui.
        slot.desiredCredits = 0; //also for how many credits wanted
        for (let i = 1; i <= slot.qty; i++)
          slot.options.push({credits: i, text: i === 1 ? 'Yarım saat' : ((i * 1/2) + " saat") });
      });

      this.ui.slotsLoading = false;
      this.ui.showAllSlots = false;
      this.slots = ret.slots;
    },
  },

  created () {
    //Without this, "ar" type support is not calculated for the initial value.
    this.fixHours();
  }

}
</script>